.subscriptionDetails {
  margin-bottom: 44px;
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 25.2px;
    letter-spacing: 0.04em;
    color: #222b2e;
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0px;
    }
    span {
      display: inline-block;
      font-weight: 600;
    }
  }
  .sancelSubscriptionBtn {
    font-size: 12px;
    font-weight: 500;
    line-height: 19.2px;
    letter-spacing: 0.04em;
    color: #ffffff;
    background-color: #2775bd;
    border-radius: 6px;
    padding: 14px 35px;
    text-decoration: none;
    transition: 0.5s;
    display: inline-block;
    position: relative;
    z-index: 3;
    border-radius: 200px;
    &:hover {
      background-color: #125593;
    }
  }
}
.subscriptionRadioBox {
  position: relative;
  margin-bottom: 44px;
  .subscriptionRadioBox__inner {
    border: 1px solid #eeeeee;
    border-radius: 16px;
    padding: 30px 34px 30px 78px;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 34px;
      width: 16px;
      height: 16px;
      border: 2px solid #2775bd;
      border-radius: 50%;
    }
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 37px;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: #2775bd;
      opacity: 0;
      transition: 0.5s;
    }
  }
  .commingSoon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: unquote('rgb(255 255 255 / 40%)'); /* Transparent overlay */
    backdrop-filter: blur(2px); /* Background blur */
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    font-weight: bold;
    z-index: 9;
    color: #ef3e6d;
  }
  input {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0;
  }
  .subscriptionRadioBox__inner.active,
  input:checked + .subscriptionRadioBox__inner {
    background-color: #eeeeee;
    &::after {
      opacity: 1;
    }
  }
  .cnWrapper {
    display: flex;
    align-items: center;
    .firstCol {
      padding-right: 20px;
      flex-shrink: 0;
      width: 290px;
      .innerWrapper {
        width: 220px;
        max-width: 100%;
      }
      .title {
        font-size: 20px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0.04em;
        text-align: center;
        margin-bottom: 2px;
        color: #222b2e;
      }
      .textDes {
        font-size: 12px;
        font-weight: 400;
        line-height: 19.2px;
        letter-spacing: 0.04em;
        text-align: center;
        color: #222b2e;
        margin-bottom: 0px;
      }
    }
    .secondCol {
      width: 100%;
      .price {
        font-size: 26px;
        font-weight: 700;
        line-height: 41.6px;
        letter-spacing: 0.04em;
        color: #43b886;
      }
    }
    .thirdCol {
      padding-left: 20px;
      flex-shrink: 0;
    }
    .currentSubscriptionBtn {
      font-size: 12px;
      font-weight: 500;
      line-height: 19.2px;
      letter-spacing: 0.04em;
      color: #43b886;
    }
    .upgradeSubscription__btn {
      font-size: 12px;
      font-weight: 500;
      line-height: 19.2px;
      letter-spacing: 0.04em;
      color: #ffffff;
      background-color: #2775bd;
      border-radius: 6px;
      padding: 8px 15px;
      text-decoration: none;
      transition: 0.5s;
      display: inline-block;
      position: relative;
      z-index: 3;
      &:hover {
        background-color: #125593;
      }
    }
  }
}


.modalCustom{
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #01183dcc;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    padding-right: 30px;
  }
  .popup-content{
    background-color: #ffffff;
    width: 634px;
    max-width: calc(100% - 30px) !important;
    border-radius: 16px !important;
    border-top: 13px solid #2775bd;
    overflow: visible;
  }
  .popup-header{
    background-color: transparent;
    border-bottom: none;
    position: relative;
    padding: 30px 24px 25px 24px;
    h2{
      font-size: 25px;
      font-weight: 700;
    }
    .close-button{
      font-size: 1.5rem;
      background: none;
      border: none;
      cursor: pointer;
      position: absolute;
      top: 0px;
      right: -36px;
      color: #ffffff;
    }
  }
  .popup-body{
    padding: 0px 0px;
    position: relative;
  }
  .scrollWrapper{
    padding: 0px 24px;
    max-height: calc(100dvh - 299px);
    overflow-y: auto;
  }
  .btnWrapperForLoader{
    padding: 40px 0px;
    display: flex;
    justify-content: center;
  }
}

.modalCustom.downgradeSubscription__modal{
  .popup-header{
    justify-content:center;
  }
  .scrollWrapper{
    p{
      font-size:20px;
      text-align:center;
      letter-spacing:0.4px;
    }
  }
  .btnWrapperForLoader{
    padding:20px 0px 40px 0px;
  }
}