.leadRowColor {
  background-color: rgba(67, 184, 134, 0.05) !important;
}
.proposalRowColor {
  background-color: rgba(255, 164, 18, 0.05) !important;
}
.customerRowColor {
  background-color: rgba(39, 117, 189, 0.05) !important;
}

.grayColor {
  color: gray !important;
}

.action-bottom-tab .action_style {
  box-shadow: none;
  border: 1px solid;
}
