body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button {
  background-color: transparent;
  border: none;
}

/* common-css-start */
.d-flex {
  display: flex;
}
.align-items-center {
  align-items: center;
}
.align-items-left {
  align-items: start;
}
.align-items-end {
  align-items: end;
}
.justify-content-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.gap-5 {
  gap: 5px;
}
.gap-10 {
  gap: 10px;
}
.gap-15 {
  gap: 15px;
}
.gap-20 {
  gap: 20px;
}
.mt-5 {
  margin-top: 5px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-25 {
  margin-top: 25px;
}

.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.mx-auto {
  margin: auto;
}
/* common-css-end */
.settingc_wrapper .tab-container {
  height: calc(100dvh - 134px);
  overflow-y: auto;
}
.settingc_wrapper .advance_import_new .tab-container {
  height: calc(100dvh - 75px);
}
.settingc_wrapper .tab-container,
.setting_card .integration-module {
  padding: 50px;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  min-width: 0;
  border-left: 2px solid #f5f6fa;
}
.setting_card .integration-module .container {
  max-width: unset;
  padding: unset;
}
.settingc_wrapper .card_design {
  border-radius: 0px;
  box-shadow: unset;
  display: flex;
  position: relative;
}
.settingc-leftbar {
  max-width: 245px;
  /* height: 100vh; */
  /* height:calc(100dvh - 134px); */
  height: calc(100dvh - 76px);
  min-width: 245px;
  /* border-right: 2px solid #F5F6FA; */
  position: sticky;
  left: 0;
  top: 7px;
}
.settingc_tabs {
  padding-left: 0px;
  margin: 0;
  height: 100%;
}
.settingc_tabs li {
  list-style: none;
  transition: all 0.2s ease-in-out;
  position: relative;
}
.settingc_tabs li::before {
  content: '';
  position: absolute;
  /* width: calc(100% - 30px); */
  width: 100%;
  height: 2px;
  background-color: #f5f6fa;
  bottom: -2px;
  right: 0px;
}
.settingc_tabs li.active {
  background-color: #2376c4;
}
.settingc_tabs li a {
  display: flex;
  text-decoration: none;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 500;
  color: #222b2e;
  padding: 18px 20px 16px 20px;
  /* margin-left: 30px; */
}
.settingc_tabs li.active a {
  color: #fff;
}
.cs_site_header .inner_header {
  justify-content: unset !important;
}
.settingc_head h5 {
  font-size: 18px;
  font-weight: 500;
  color: #222b2e;
  margin-bottom: 0px;
}
.settingc_head {
  width: 218px;
  max-width: 218px;
}
.cs_site_header .right_wrapper {
  margin-left: auto;
}
.settingc_wrapper .setting_card .setting__container {
  padding: 25px 0px;
}
.user_tabs ul {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}
.user_tabs a {
  font-size: 12px;
  color: #222b2e;
  font-weight: 400;
  text-decoration: none;
  border: 1px solid #eeeeee;
  padding: 8px 12px;
  border-radius: 8px;
}
.user_tabs a:hover {
  background-color: #2775bd;
  border-color: #2775bd;
  color: #fff;
}
.user_tabs ul li {
  list-style: none;
  margin-right: 12px;
}
.users_title {
  margin-top: 35px;
}
.users_title h3 {
  font-size: 20px;
  font-weight: 600;
  color: #222b2e;
  margin-bottom: 4px;
}
.users_title p {
  font-size: 14px;
  color: rgba(34, 43, 46, 0.8);
  font-weight: 400;
}
.import_wrap {
  padding: 40px 60px 40px 36px;
  display: flex;
  align-items: center;
  margin: 0px -10px 30px;
}
.import_title h4,
.import_paragraph p {
  margin-bottom: 0px;
}
.import_title h4 {
  font-size: 20px;
  font-weight: 700;
  color: #222b2e;
}
.import_title,
.import_button {
  width: 20% !important;
  padding: 0px 10px;
}
.setting_card .integration-module .social-contect-listbox .social-img-col {
  width: 220px;
  padding-left: 0px;
  padding-right: 0px;
}
.setting_card .integration-module .social-contect-listbox .connect-col {
  padding-left: 0px;
  padding-right: 0px;
  width: 250px;
}
.import_paragraph {
  width: 60% !important;
  padding: 0px 10px;
}
.setting_card .integration-module .social-contect-listbox .content-list-col {
  width: calc(100% - 470px);
  padding-left: 20px;
  padding-right: 20px;
}
.setting_card .integration-module .social-contect-listbox .col {
  max-width: unset !important;
  display: inline-block !important;
}

.import_paragraph p {
  font-size: 14px;
  color: #222b2e;
  font-weight: 400;
  line-height: 1.4;
}
.get_btn {
  background-color: #36ce8d;
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  padding: 10px 15px;
  border-radius: 8px;
  border: 1px solid transparent;
}
.get_btn.connectedBtn {
  background-color: rgba(67, 184, 134, 0.15);
  color: #43b886;
  width: 120px;
  text-align: center;
}
.import_button {
  text-align: right;
}
.main_wrapper {
  overflow: hidden;
}
.main_wrapper2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 255px;
  -webkit-transition: padding 0.2s;
  -o-transition: padding 0.2s;
  transition: padding 0.2s;
}
.main_wrapper::after,
.main_wrapper::before {
  z-index: 9 !important;
}
.app-sidebar .left_sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 15;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-transition: width 0.2s;
  -o-transition: width 0.2s;
  transition: width 0.2s;
  z-index: 2;
}
.free-trial-bar-active {
  padding-top: 0px;
}
.main_wrapper .rightC_wrapper {
  left: unset !important;
  width: 100% !important;
}
.free-trial-bar-active .main_wrapper .rightC_wrapper {
  padding-top: 30px !important;
  margin-top: 132px;
}
.free-trial-bar-active .rightC_wrapper-full.action-pt {
  padding-top: 0px !important;
}
.chart_wrapper .apexcharts-canvas {
  width: 320px !important;
}
.cs-people_db_row {
  width: 100% !important;
}
.setting_card .card__header__two {
  padding: 10px 0px 0px !important;
}
.card__header__two .nav-tabs-wrap .nav-tabs {
  gap: 20px;
}
.card_header_settings .nav-tabs-wrap .nav-tabs .nav-item {
  padding: 10px 24px !important;
  outline: unset;
}
.password_note_fill .pnf_list_item {
  margin-bottom: 15px !important;
}
.setting__container .cs_form_row {
  margin-bottom: 30px !important;
}
.inviteUserHeader h2 {
  color: #222b2e !important;
  font-weight: 600 !important;
}
.inviteUserHeader p {
  font-weight: 400 !important;
  color: #222b2e !important;
  opacity: 0.8;
}
.manageAccess-row {
  background: transparent !important;
  border-radius: unset !important;
  width: 100% !important;
  padding: 0px 15px !important;
  margin-bottom: 20px !important;
}
.manageAccess-row .manageAccess-column {
  background: #f8f9fb !important;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 15px !important;
  border-radius: 15px;
}
.manageAccess-head {
  padding: 0px 15px;
  width: 100% !important;
}
.manageAccess-row .manageAccess-column {
  background: #f8f9fb !important;
  display: flex;
  align-items: center;
}
.manageAccess-head-cont {
  display: flex;
  align-items: center;
  padding: 0px 15px !important;
  width: 100%;
}
.manageAccess-row-wrap:before {
  content: unset !important;
}
.setting__container .ff_faq_box .inner_box {
  border: unset !important;
  background: #f8fafc;
}
.commission-structure-select {
  border: unset !important;
}
.setting__container .commission_setting_tab,
.employee_dashboard_form {
  width: 50% !important;
}
.commission-structure-wrap .commission-structure-inner {
  flex: unset !important;
  width: 100%;
}
.setting__container .ff_faq_box .ff_faq_contant .ff_faq_contant_inner {
  padding: 30px 20px 30px 40px !important;
}
.employee_dashboard_form .cs_form_submit_row.cs_form_submit_row-right {
  justify-content: start !important;
}
.sales-stage-block,
.commission_setting-wrap {
  border: unset !important;
  padding: unset !important;
}
.roi-calculations-tab {
  max-width: 1020px !important;
}
.roi-calculations-tab .ff_faq_box {
  padding: 0px !important;
}
.roi-calculations-tab p,
.roi-calculations-tab .roi-profit .roi-profit-lbl {
  color: #222b2e !important;
}
.roi-calculations-tab .roi-profit .roi-profit-lbl {
  font-weight: 600 !important;
}
.site_header .inner_header {
  gap: 10px 0px !important;
}
.main_wrapper2 .rightC_wrapper.settingc_wrapper {
  /* padding: 0px 0px 34px !important; */
  padding: 0px 0px 0px 0px !important;
  width: 100% !important;
  left: unset !important;
  margin-top: 0px;
}
.free-trial-bar-active .main_wrapper2 .rightC_wrapper.settingc_wrapper {
  margin-top: 132px;
}
.sidebar-right-active {
  padding-left: 80px;
}
.sidebar-left-active .left_sidebar {
  width: 80px !important;
}
.sidebar-left-active .left_sidebar .navitems_wrapper .nav_item .menu_title {
  display: none;
}
.sidebar-left-active::before,
.sidebar-left-active::after,
.sidebar-right-active .free-trial-bar,
.sidebar-right-active .site_header {
  left: 80px !important;
}
.sidebar-sm {
  display: none;
}
.sidebar-left-active .sidebar-sl {
  display: none;
}
.sidebar-sm {
  width: 44px;
  margin: 0 auto;
}
.sidebar-left-active .sidebar-sm {
  display: block;
}
.navitems_leftarrow {
  padding: 10px;
  margin-top: 10px;
}
.navitems_leftarrow a {
  width: 32px;
  height: 32px;
  /* background: #fff; */
  /* border-radius: 50px; */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease-in-out;
}
.navitems_leftarrow a img {
  width: 22px;
}
.main_wrapper .navitems_leftarrow a {
  transform: rotate(180deg);
}
.sidebar-left-active .navitems_leftarrow a {
  transform: rotate(0deg);
}
.sale_data_setting .upload-file-img {
  margin: unset !important;
  text-align: left !important;
}
.setting_card .integration-module .social-contect-listbox {
  padding: 30px 30px 30px 30px !important;
  border: 1px solid #eeeeee !important;
}
.setting_card .integration-module .social-contect-listbox .content-list-col ul li {
  font-weight: 400;
  margin-bottom: 8px;
}
.action-card-wrap {
  display: flex;
}
.col-50 {
  width: 50%;
}
.action-card-header {
  padding: 40px;
}
.action-card-header .backtop {
  padding: 0px !important;
}
.action-card-header .action-header {
  padding: 15px 0px;
}
.action-pt {
  padding-top: 0px !important;
}
.h-vh {
  height: 100vh;
}
.action-card-wrap .action-card {
  min-height: unset !important;
  padding: 45px;
  height: 100%;
}
.action-card-wrap .action-tab-wrap {
  box-shadow: unset !important;
  border-bottom: 2px solid #f5f6fb;
  padding: 8px 0px;
}
.action-card-wrap .action-tab_list ul {
  gap: 10px 15px !important;
}
.action-card-wrap .action-tab_list ul li .action-link::after {
  bottom: -10px;
}
.action-card-wrap .tab_contant__actionsnew {
  padding: 0px 0px;
  margin-top: 60px;
}
.action-middle-wrap {
  margin: 25px 0px 42px;
}
.action-title h3 {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
}
.action-card-cont {
  border-radius: 16px;
  background-color: #fff;
  padding: 14px 20px;
  box-shadow: 0px 4px 16px rgb(0 0 0 / 5%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  min-height: 62px;
  position: relative;
  cursor: pointer;
}
.action-card-title {
  width: 40%;
}
.action-card-title h4 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.action-card-price {
  width: 30%;
  display: flex;
  justify-content: center;
}
.action-point {
  width: 30%;
  justify-content: end;
}
.action-card-title h4,
.action-card-price p,
.action-point p,
.tabs-body p {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0px;
  color: #222b2e;
}
.action-point {
  display: flex;
  align-items: center;
  gap: 20px;
}
.action-shap {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bg-green {
  background-color: #e3f5ed;
  color: #43b886;
}
.tabs-body h4 {
  font-size: 14px;
  font-weight: 400;
  color: #2775bd;
  margin-bottom: 8px;
}
.tab-list {
  display: flex;
  flex-wrap: wrap;
  gap: 34px 0px;
  background: #f4f5fa;
  padding: 25px 20px;
  border-radius: 10px;
  max-width: 650px;
  position: relative;
  margin-bottom: 40px;
}
.tab-list.contacts {
  padding: 60px 20px 25px 20px;
}
.tab-list .primaryContact {
  font-size: 14px;
  font-weight: 400;
  color: #43b886;
  letter-spacing: 0.5px;
  background-color: #d9f1e7;
  position: absolute;
  top: 14px;
  padding: 8px 12px 8px 30px;
  left: 28px;
  border-radius: 6px;
}
.tab-list .primaryContact::before {
  content: '';
  position: absolute;
  top: 10px;
  left: 9px;
  width: 12px;
  height: 5px;
  border-left: 2px solid #43b886;
  border-bottom: 2px solid #43b886;
  transform: rotate(-45deg);
}
.tab-list-icons {
  position: absolute;
  right: 10px;
  top: 10px;
}
.tab-list.contacts .tab-list-icons {
  top: 20px;
}
.tabs-body {
  flex-basis: 33.33%;
  padding: 0px 10px;
}
.col-12 {
  flex-basis: 100%;
}
.action-header_top__left {
  display: flex;
  align-items: center;
}
.action-header_top__left .dropdown_v1 .dropdown_head .dropdown_text {
  padding: 0px 0px 0px 7px;
  font-weight: 700;
  line-height: 20px;
}
.action-header_top__left .dropdown_v1 .dropdown_head .dropdown_arrow {
  padding: 0px 5px 0px 0px;
  justify-content: end;
  min-width: 26px;
  border-left: 0px;
}
.action-card-wrap .action-tab_list ul li .action-link,
.action-card-wrap .action-tab_list ul li .action-link.active {
  color: #222b2e;
  font-weight: 400;
}
.action-card-wrap .action-tab_list ul li .action-link::after {
  height: 2px;
}
.action-card-wrap .action-tab_list ul li .action-link::after {
  background: #2775bd;
}
.action-tabs-one {
  position: relative;
}
.action-tabs-one .modal-close-btn {
  width: 100%;
  margin-bottom: 8px;
  text-align: right;
}
.action_style {
  /* background-color: #D3E3FC; */
  background-color: #ffffff;
  border-radius: 50px;
  padding: 16px 20px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.18);
}
.action_style span {
  font-size: 12px;
  font-weight: 600;
  color: #222b2e;
}
.action_style svg {
  fill: #222b2e;
  width: 14px;
}
.button-set-wrp {
  margin-top: 25px;
  margin-bottom: 20px;
}
/* Time_Line */
.content-timeline {
  margin-top: 60px;
  overflow-y: scroll;
}
.content-timeline-wrap {
  padding-bottom: 20px;
}
.timeline-icon {
  width: 35px;
  height: 35px;
  border-radius: 50px;
  margin: 0 auto;
}
.timeline-icon.history_call,
.timeline-icon.history_meeting,
.timeline-icon.history_task {
  width: unset;
  height: unset;
  border-radius: 3px;
  padding: 5px 10px;
}

.timeline-icon.history_meeting {
  background-color: #fcd8e2;
}
.timeline-line {
  position: relative;
  z-index: 0;
  width: 60px;
  text-align: center;
}
.time_line_date-wrap {
  position: relative;
}
.infinite-scroll-component ul:last-child::before {
  min-height: calc(100% - 62px);
}
.time_line_date-wrap:before {
  content: '';
  width: 2px;
  min-height: 100%;
  background-color: #f5f6fa;
  top: 0px;
  position: absolute;
  z-index: 0;
  left: 108px;
}

.light-green {
  background-color: #e3f5ed;
}
.light-orange {
  background-color: #ffedd0;
}
.light-purple {
  background-color: #efecff;
}
.light-blue {
  background-color: #dbe8f4;
}
.timeline-date p {
  font-size: 14px;
  font-weight: 400;
}
.text-darblack {
  color: #222b2e;
}
.text-blue {
  color: #2775bd;
}
.timeline-date p {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0px;
}
.content-timeline ul {
  padding-left: 0px;
  margin: 0px 0px;
  padding-bottom: 40px;
}
.content-timeline ul:last-child li .timeline-line::before {
  min-height: 36px;
}
.time_line_date {
  background-color: #f4f7ff;
  border-radius: 8px;
  padding: 8px 15px;
  display: inline-block;
  margin-left: 70px;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
}
.time_line_date p {
  margin-bottom: 0px;
  color: #222b2e;
  font-size: 12px;
  font-weight: 400;
}
.notes-wrap .chatMsg {
  flex-wrap: wrap;
  gap: 0 !important;
}
.notes-wrap .time {
  order: 1;
  width: 100%;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #222b2e !important;
  margin-bottom: 5px !important;
  top: 0px !important;
}
.notes-wrap .help_csm_co_msg_text {
  order: 2;
  margin-bottom: 5px;
  width: 100%;
}
.notes-wrap .help_csm_co_msg_text .chat_upload_file_name,
.notes-wrap .img_msg_cont {
  width: 98px !important;
}
.notes-wrap .text {
  order: 3;
  margin-bottom: 5px;
  width: 100%;
}
.sales-team-body .app-sidebar .left_sidebar,
.sales-team-body .modal_hide_loader {
  display: none;
}
.sales-team-body .main_wrapper2 {
  padding-left: 0px;
}
.sales-team-body .main_wrapper::before,
.sales-team-body .main_wrapper::after {
  content: unset !important;
}
.add-setting-admin-changes .modify-rate {
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: center;
  gap: 20px;
}
.bg-red {
  background-color: rgba(255, 68, 90, 0.2);
  color: #ef3e6d;
}
.bg-orange {
  background-color: rgb(255, 164, 18, 0.2);
  color: #fea22f;
}
.action-border .action-card-cont {
  margin-bottom: 0px;
}
.action-card-header .dropdown_list ul li {
  text-align: left;
  border-bottom: 0px;
  padding: 4px 15px 4px !important;
}
.action-card-header .dropdown_list {
  border-radius: 15px;
  padding: 10px;
}
.action-middle-wrap p {
  color: #939ea3;
  margin-top: 5px;
  font-size: 12px;
  font-weight: 400;
}
.card_design .img_cover img {
  width: 100%;
  margin-top: 10px;
}
.card_design .cs_inner_box {
  padding: 40px 38px 20px !important;
}
.cs_inner_box .inner_wrapper img,
.cs_inner_box .img_cover img {
  width: 100%;
  height: 120px;
  object-fit: contain;
}
.card_design .cs_chatboard_uploadDropdown_wrapper .cs_chatboard_uploadDropdown {
  right: -136px !important;
}
.tab-note-header {
  border-top: 2px solid #f5f6fa;
  padding: 16px 0px;
  color: #2775bd;
}
.active-tab-card {
  border: 2px solid #2775bd;
  position: relative;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
  box-shadow: unset !important;
}
.action-btns,
.action-sucess .btn_green_v1 {
  padding: 9px 28px !important;
  border-radius: 5px !important;
}
.card-cont_border:before,
.card-cont_border::after {
  content: '';
  width: 25px;
  position: absolute;
  height: 13px;
  background: #f5f6fa;
  right: 0;
}
.card-cont_border:before {
  top: -12px;
}
.card-cont_border::after {
  bottom: -12px;
}
.card-cont_border .shapw-up,
.card-cont_border .shapw-down {
  position: absolute;
  z-index: 1;
  right: 0;
}
.card-cont_border .shapw-up svg,
.card-cont_border .shapw-down svg {
  width: 36px;
}
.card-cont_border .shapw-up svg {
  height: 21px;
}
.card-cont_border .shapw-down svg {
  height: 21.5px;
}

.action-card-cont .shapw-up,
.action-card-cont .shapw-down {
  display: none;
  position: absolute;
  right: -40px;
}
.action-card-cont.active-tab-card:before {
  content: '';
  position: absolute;
  top: 0px;
  right: -40px;
  height: 62px;
  width: 40px;
  background-color: #ffffff;
}
.action-card-cont.active-tab-card .shapw-up,
.action-card-cont.active-tab-card .shapw-down {
  display: block;
}
.action-card-cont .shapw-up {
  bottom: 100%;
  width: 48px;
  height: 14px;
}
.action-card-cont .shapw-up:before {
  content: '';
  position: absolute;
  bottom: -3px;
  right: -3px;
  background-image: linear-gradient(-45deg, #ffffff 50%, transparent 50%);
  width: 12px;
  height: 13px;
}
.action-card-cont .shapw-up:after {
  content: '';
  bottom: 0px;
  width: 48px;
  height: 14px;
  border-bottom: 2px solid #2775bd;
  border-right: 1px solid #2775bd;
  border-bottom-right-radius: 13px;
  position: absolute;
  right: 0px;
}
.action-card-cont .shapw-up svg {
  display: none;
}
.action-card-cont .shapw-down {
  bottom: -14px;
  width: 48px;
  height: 14px;
}
.action-card-cont .shapw-down::before {
  content: '';
  position: absolute;
  top: -3px;
  right: -3px;
  background-image: linear-gradient(-135deg, #ffffff 50%, transparent 50%);
  width: 12px;
  height: 13px;
}
.action-card-cont .shapw-down:after {
  content: '';
  top: 0px;
  width: 48px;
  height: 14px;
  border-top: 2px solid #2775bd;
  border-right: 1px solid #2775bd;
  border-top-right-radius: 13px;
  position: absolute;
  right: 0px;
}
.action-card-cont .shapw-down svg {
  display: none;
}

.nav-tabs.cs-nav-tabs li.nav-item {
  padding: 10px 0px !important;
}
.action-card-cont .card-cont_border {
  position: absolute;
  width: 40px;
  height: 62px;
  background: #fff;
  right: -40px;
  border: 2px solid #2775bd;
  border-right: 0;
  border-left: 0;
  display: none;
  cursor: pointer;
}
.action-card-cont:hover {
  box-shadow: 0 2px 16px 0 rgba(35, 118, 196, 0.4);
}
.action-card-cont.active-tab-card .card-cont_border {
  display: block;
}
.action_card_left {
  max-height: 846px;
  overflow-y: auto;
  min-height: 846px;
}
.free-trial-bar-active .action_card_left {
  max-height: 786px;
  min-height: 786px;
}
.free-trial-bar-active .rightC_wrapper-full.action-pt {
  height: calc(100vh - 132px);
  overflow: hidden;
}
.rightC_wrapper-full.action-pt {
  height: calc(100vh - 73px);
  overflow: hidden;
}
.action-tabs-sec,
.infinite-scroll-component::-webkit-scrollbar,
.tab_list_wrap::-webkit-scrollbar,
.content-timeline::-webkit-scrollbar,
.action_card_left::-webkit-scrollbar,
.action_card_right .chatBox__notes .chatContant::-webkit-scrollbar,
.action_card_right .table_scroll::-webkit-scrollbar {
  display: none;
}
.action_card_left::-moz-scrollbar {
  display: none;
}
.action_card_right .chatBox__notes .chatFooter {
  position: relative;
  border-top: 0px;
}
.action_card_right .chatBox__notes {
  padding-top: 0px;
  margin-top: 60px;
}
/* .action_card_right .chatBox__notes .chatContant, .tab_list_wrap, .action_card_right .table_scroll, .action_card_right .content-timeline{
    min-height: calc(100vh - 320px);
    overflow-y: auto;
    max-height: calc(100vh - 320px);
    padding-top: 0px;
}
.free-trial-bar-active .action_card_right .chatBox__notes .chatContant, .free-trial-bar-active .tab_list_wrap, .free-trial-bar-active .action_card_right .table_scroll{
   min-height: calc(100vh - 380px);
    overflow-y: auto;
    max-height: calc(100vh - 380px); 
} */

.action_card_right .action-tabs-one .chatBox__notes .chatContant {
  max-height: calc(100vh - 580px);
  margin-top: 20px;
  min-height: calc(100vh - 580px);
  padding-top: 0px;
}
.free-trial-bar-active .action_card_right .action-tabs-one .chatBox__notes .chatContant {
  max-height: calc(100vh - 620px);
  min-height: calc(100vh - 620px);
}
.action_card_right .action-tabs-one .chatBox__notes {
  margin-top: 0;
}
.action_card_right .tab_contant__notes,
.action_card_right .chatBox__notes {
  height: unset !important;
}

.action_card_right .action-bottom-tab {
  margin-top: 0px;
  padding: 16px 24px;
}
.action_card_right .cs_contant__transactions {
  padding: 0px 30px;
  margin-top: 60px;
}
.lead-card .cs_form_row {
  margin-bottom: 0px !important;
}
.notes_close_btn {
  position: absolute;
  right: 0px;
  font-size: 24px;
  transform: rotate(45deg);
  top: 0;
}
.connected-wrap .img_cover {
  width: 100%;
  position: relative;
  height: 100%;
  display: block;
}
.inner_wrapper .file_text {
  text-align: center;
  margin-top: 10px;
}
.sales-title h4 {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 15px;
  font-weight: 500;
  margin: 40px 0px 25px;
}
.bagdge {
  color: #206cb4;
  font-weight: 400;
}
.line-throw {
  position: relative;
}
.line-throw::before {
  content: '';
  width: calc(100% - 560px);
  height: 2px;
  background: rgb(0 0 0 / 10%);
  position: absolute;
  top: 16px;
  left: 0;
  z-index: 0;
}
.chatMsg.chatMsg__file,
.chatMsg {
  position: relative;
  cursor: pointer;
}
.chatMsg .edit-del-btn {
  position: absolute;
  top: 0;
  right: 0;
  visibility: hidden;
}
.chatMsg.chatMsg__file:hover .edit-del-btn,
.chatMsg:hover .edit-del-btn {
  visibility: visible;
}
.action_card_right .tab_contant__notes__action .help_csm_co_msg_text {
  margin-bottom: 8px;
}
.action_card_right .chatMsg.chatMsg__file .text {
  width: 100% !important;
  padding-left: 0px !important;
}

@media only screen and (max-width: 1600px) {
  .setting_card .integration-module .social-contect-listbox .social-img-col {
    width: 140px;
  }
  .setting_card .integration-module .social-contect-listbox .content-list-col {
    width: calc(100% - 260px);
  }
  .setting_card .integration-module .social-contect-listbox .connect-col {
    width: 120px;
  }
  .integration-module p.conn-info {
    margin-right: 0px !important;
    margin-bottom: 10px !important;
    width: 100% !important;
  }
  .get_btn.connectedBtn {
    width: 100%;
  }
}

@media only screen and (max-width: 1440px) {
  .action-card-wrap .action-card,
  .action-card-header {
    padding: 38px;
  }
  .action_card_right .cs_contant__transactions,
  .content-timeline,
  .action-card-wrap .tab_contant__actionsnew,
  .action_card_right .tab_contant__notes,
  .action_card_right .chatBox__notes {
    margin-top: 40px !important;
  }
  .action_card_right .chatBox__notes .notes-wrap {
    padding-top: 0px;
  }
  .action_card_left {
    max-height: 666px;
    min-height: 666px;
  }
  .action-card-wrap .action-tab_list ul {
    gap: 10px 10px !important;
  }
  .free-trial-bar-active .action_card_left {
    max-height: 606px;
    min-height: 606px;
  }
}
@media only screen and (max-width: 1366px) {
  .action-middle-wrap {
    margin: 20px 0px 28px;
  }
  .tab-list {
    gap: 10px 0px;
    padding: 18px 12px;
  }
  .action-card-wrap .action-card,
  .action-card-header {
    padding: 28px;
  }

  .action_card_right .cs_contant__transactions {
    padding: 0px 20px;
  }
  .action_card_left {
    max-height: 694px;
    min-height: 694px;
  }
  .free-trial-bar-active .action_card_right .chatBox__notes .chatContant,
  .free-trial-bar-active .tab_list_wrap,
  .free-trial-bar-active .action_card_right .table_scroll,
  .free-trial-bar-active .content-timeline {
    margin-top: 0px;
    padding: 0px;
  }
  .action_card_right .content-timeline,
  .action_card_right .table_scroll,
  .action_card_right .chatBox__notes .chatContant,
  .tab_list_wrap {
    margin-top: 0px;
    padding: 0px;
  }
  .free-trial-bar-active .content-timeline,
  .action_card_right .cs_contant__transactions,
  .action_card_right .chatBox__notes,
  .action-card-wrap .tab_contant__actionsnew,
  .content-timeline {
    margin-top: 40px !important;
  }
  .action-card-wrap .action-tab_list ul {
    gap: 10px 4px !important;
  }
  .action-tab_list ul li .action-link {
    font-size: 12px !important;
  }
  .action_card_right .action-tabs-one .chatBox__notes .chatContant,
  .free-trial-bar-active .action_card_right .action-tabs-one .chatBox__notes .chatContant {
    max-height: calc(100vh - 490px) !important;
    min-height: calc(100vh - 490px) !important;
    margin-top: 5px;
  }
  .free-trial-bar-active .action_card_right .action-tabs-one .chatBox__notes .chatContant {
    max-height: 70px !important;
    min-height: 70px !important;
  }

  .main_wrapper .rightC_wrapper-full .tab_contant__notes,
  .action_card_right .action-tabs-one .chatBox__notes {
    margin-top: 0 !important;
  }
  .action-card-cont .card-cont_border {
    width: 28px;
    right: -28px;
  }
}
@media only screen and (max-width: 1199px) {
  .main_wrapper2 {
    padding-left: 0px;
  }
}

.action-tab__left {
  min-width: 500px;
}

.primaryContact_input {
  accent-color: #43b886;
  content: '';
  position: absolute;
  top: 5px;
  left: 6px;
  width: 13px;
  height: 13px;
  border: 3px solid #43b886;
}
.action_detail_loader {
  width: 100%;
}
.lead_cs_form_row_three {
  gap: 10px;
}
.lead_cs_form_row_two {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.lead_cs_form_row_two .cs_form_row {
  width: calc(50% - 5px);
}

.invite_user_noData {
  padding: 0px !important ;
}

.fancyScrollbarRound::-webkit-scrollbar,
.settingc_wrapper .tab-container::-webkit-scrollbar,
.integration-module::-webkit-scrollbar {
  width: 8px;
}
.fancyScrollbarRound::-webkit-scrollbar-track,
.settingc_wrapper .tab-container::-webkit-scrollbar-track,
.integration-module::-webkit-scrollbar-track {
  border-radius: 4px;
}
.fancyScrollbarRound::-webkit-scrollbar-thumb,
.settingc_wrapper .tab-container::-webkit-scrollbar-thumb,
.integration-module::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #2376c4;
}
