.codeBlockWrap {
  .commission_setting-block {
    width: 100%;
  }
  .formCustomField .table_td {
    width: 20% !important;
  }
  .commision_setting_title {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  .sub-title {
    margin: 0;
  }
  .add_new_btn {
    display: inline-block;
    font-family: 'Roboto';
    font-weight: 400;
    color: #ffffff;
    border: 1px solid #36ce8d;
    border-radius: 5px;
    text-decoration: none;
    padding: 7px 13px;
    font-size: 12px;
    transition: 0.5s;
    cursor: pointer;
    margin-left: 15px;
    background-color: #36ce8d;
    box-shadow: 0 3px 7px rgba(154, 160, 185, 0.05), 0 4px 20px rgba(166, 173, 201, 0.2);
  }
  .newFieldForm {
    margin-top: 20px;
  }
  .cfm_row {
    margin-left: -6px;
    margin-right: -6px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 18px;
  }
  .cs_form_double_row .cs_form_row {
    width: 20%;
  }
  .table_row.showEditButton .table_rowLS {
    display: flex !important;
  }
  .table_row.showEditButton {
    background-color: #f6f8fa;
  }
  .cs_form_row {
    width: 18%;
    margin: 0 10px;
  }
  button.add_more_btn {
    margin-bottom: 10px;
  }
  .data_tab_HS .package_cs_select_box > div > div:first-of-type {
    background-color: #ffffff;
    border: 2px solid #e6edf1;
    height: 29px;
    width: 193px;
    padding: 0 !important;
    padding: 10px;
  }
  .data_tab_HS .package_cs_select_box > div > div:first-of-type div svg {
    color: red;
  }
  .data_tab_HS .package_cs_select_box > div > div:first-of-type {
    background-color: #ffffff;
    border: 2px solid #e6edf1;
    height: 29px;
    width: 193px;
  }
  .data_tab_HS .package_cs_select_box > div > div:first-of-type div {
    position: relative;
    top: -2px;
  }
  .table_td.company {
    text-transform: capitalize;
  }
}
.codeBlockWrap {
  .relative-code {
    position: relative;
  }
  .flex-code {
    display: flex;
  }
  .absolute-code {
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    display: flex;
    align-items: center;
    border: 1px solid white;
    border-radius: 5px;
    padding: 7px;
  }
  span.languageSpan {
    margin-right: 10px;
  }
  .relative-code img {
    filter: invert(100%) sepia(5%) saturate(7482%) hue-rotate(210deg) brightness(108%) contrast(91%);
  }
  pre {
    border-radius: 10px;
    padding: 1em !important;
  }
}
.calander__integration {
  .error {
    color: #ef3e6d;
  }
  .back-btn {
    margin-bottom: 14px;
  }
  .section__title {
    font-weight: 600;
    margin-bottom: 15px;
  }
  .img__box {
    border: 1px solid #e5e5e5;
    padding: 10px;
    background-color: #f5f6fa;
    border-radius: 12px;
    width: 100%;
    position: relative;
  }

  .email__provider__wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
  }

  .email__provider__box {
    width: 140px;
    padding-right: 12px;
  }

  .img__box img {
    object-position: center;
    object-fit: contain;
    width: 106px;
    height: 106px;
    padding: 22px;
  }

  .inner__box {
    position: relative;
  }

  h4.title_box {
    text-align: center;
    font-family: 'Roboto';
    margin-top: 10px;
    font-size: 14px;
  }
  .connected_account_wrapper {
    background-color: #f4f5fa;
    display: flex;
    padding: 20px;
    align-items: center;
    border-radius: 10px;
    width: 500px;
    max-width: 100%;
    // width: fit-content;
  }

  .connected__account {
    display: flex;
    align-items: center;
    width: calc(100% - 90px);
    margin-right: 5px;
  }
  .email__info p {
    margin: 0;
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 5px;
    letter-spacing: 1px;
    width: 100%;
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .email__info span {
    font-size: 12px;
    font-weight: 400;
    color: #59626b;
    letter-spacing: 1px;
  }

  .email__info {
    padding-left: 20px;
    width: 100%;
    padding-right: 20px;
  }
  .button__wrap {
    display: flex;
    margin-left: 30px;
  }
  .button__wrap span {
    margin-right: 10px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    transition: 0.4s;
    border-radius: 4px;
    svg {
      width: 100%;
      height: 100%;
    }
    &:hover {
      background-color: #ffffff;
    }
  }
  .button__wrap span:last-child {
    margin-right: 0px;
  }
  .get_btn {
    margin-top: 20px;
  }
}

// .syncEventModal .inner_box {
//   width: 400px;
//   max-width: 100%;
//   height: 300px;
// }

// .syncEventModal p {
//   text-align: center;
//   font-size: 20px;
// }
// .syncEventModal .row_modal {
//   margin-bottom: 0;
// }

h3.oldEvents {
  margin-top: 20px;
  cursor: pointer;
  display: inline-block;
}
.quickbook_actions {
  margin-top: 20px;
}

.quickbook_actions span {
  text-decoration: underline;
  cursor: pointer;
}
.back-btn.btn {
  padding: 10px;
  display: flex;
  max-width: 200px;
  align-items: center;
}
.back-btn img {
  width: 10% !important;
  margin-right: 10px;
}

.integration-module {
  height: calc(100dvh - 134px);
  overflow-y: auto;
}
.integration-module {
  button:disabled,
  button[disabled] {
    border: 1px solid #999999;
    background-color: #e9e9e9 !important;
    color: #666666 !important;
    cursor: not-allowed;
  }
  .integration-new-design {
    margin-bottom: 50px;
  }
  .integration-new-design .container {
    width: 100%;
  }
  img {
    width: auto;
  }
  .container {
    max-width: 1240px;
    padding: 0 15px;
  }
  .social-contect-listbox {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border: 1px solid #000000;
    border-radius: 15px;
    padding: 20px 10px;
    margin-bottom: 20px;
  }
  .social-contect-listbox:last-child {
    margin-bottom: 0;
  }
  .social-contect-listbox .col {
    padding: 0 10px;
    width: 100%;
  }
  .social-contect-listbox .social-img-col {
    max-width: 290px;
  }
  .social-img-col img {
    width: 100%;
  }
  .social-contect-listbox .connect-col {
    max-width: 200px;
  }
  .social-contect-listbox .content-list-col {
    max-width: calc(100% - 550px);
  }
  .social-contect-listbox .social-img-col .img-wrap {
    max-width: 90%;
  }
  .social-contect-listbox .connect-col {
    text-align: right;
    display: grid;
  }
  p.conn-info {
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    padding: 10px 15px;
    border-radius: 8px;
    border: 1px solid #36ce8d;
    color: #36ce8d;
    display: inline-block;
    margin-right: 10px;
    transition: 0.4s;
    margin-bottom: 0px;
    width: 120px;
    text-align: center;
  }
  p.conn-info:hover {
    background-color: rgba(67, 184, 134, 0.15);
  }
  .img-wrap.both-images {
    display: flex;
  }
  .both-images img {
    width: 50%;
    margin-right: 30px;
  }
  .social-contect-listbox .content-list-col ul {
    padding-left: 15px;
  }
  .social-contect-listbox .content-list-col ul li {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.2;
  }
  .social-contect-listbox .connect-btn {
    text-decoration: none;
    background-color: #4a86e8;
    color: #ffffff;
    font-size: 18px;
    text-transform: capitalize;
    padding: 10px 20px;
    border-radius: 8px;
  }
}

.syncEventModal {
  .row_modal {
    gap: 10px;
  }
}
