/*mail listing css start*/
.mailList__col {
  font-family: 'Roboto', sans-serif;
  margin-bottom: 15px;
  cursor: pointer;
  background-color: #f6f8fa;
  padding: 20px 15px;
  border-radius: 10px;
}
.mailList__col:last-child {
  margin-bottom: 80px;
}
.mailList__col .nameTime {
  display: flex;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  justify-content: space-between;
  margin-bottom: 2px;
}
.mailList__col .nameTime .name {
  color: #2775bd;
  font-size: 16px;
  font-weight: 400;
  margin: 0px 0px;
  width: 100%;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 15px;
  margin-bottom: 5px;
}
.mailList__col .nameTime .time {
  color: #222b2e;
  font-size: 14px;
  font-weight: 400;
  display: inline-block;
  flex-shrink: 0;
  margin-bottom: 5px;
}
.mailList__col .toSend {
  display: flex;
  /* align-items: center; */
  flex-wrap: wrap;
  font-size: 12px;
  font-weight: 400;
}
.mailList__col .toSend .label {
  color: #5c6363;
  display: inline-block;
  flex-shrink: 0;
  margin-right: 5px;
}
.mailList__col .toSend .value {
  color: #2775bd;
  /* display: inline-block; */
  display: flex;
  flex-direction: column;
  gap: 5px;  
  max-width: 100%;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mailList__col .mailTitle {
  font-weight: 700;
  color: #222b2e;
  font-size: 14px;
  line-height: 20px;
  margin: 0px 0px 6px 0px;
}
.mailList__col .mailList__body {
  display: flex;
  align-items: center;
  margin-top: 16px;
}
.mailList__col .mailList__body .details {
  width: calc(100% - 30px);
  padding-right: 13px;
}
.mailList__col .decriptionText {
  font-size: 12px;
  font-weight: 400;
  color: #5c6363;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  letter-spacing: 1px;
  margin: 0px 0px;
}
.mailList__col .attachmentBtn {
  position: relative;
  width: 30px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  padding: 6px 8px;
  cursor: pointer;
  flex-shrink: 0;
}
.mailList__col .attachmentBtn svg {
  width: 100%;
  height: 100%;
}
.mailList__col .attachmentBtn:before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background-color: #2775bd;
  opacity: 0;
  transition: 0.4s;
}
.mailList__col .attachmentBtn:hover:before {
  opacity: 0.1;
}
/*mail listing css end*/

/*mail details modal css start*/
.mailDetails__wrapper {
  font-family: 'Roboto', sans-serif;
  padding: 54px 44px;
}
.mailDetails__wrapper .nameTime {
  display: flex;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  justify-content: space-between;
  margin-bottom: 2px;
}
.mailDetails__wrapper .nameTime .name {
  color: #2775bd;
  font-size: 16px;
  font-weight: 400;
  margin: 0px 0px;
  width: 100%;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 15px;
  margin-bottom: 5px;
}
.mailDetails__wrapper .nameTime .time {
  color: #222b2e;
  font-size: 14px;
  font-weight: 400;
  display: inline-block;
  flex-shrink: 0;
  margin-bottom: 5px;
}
.mailDetails__wrapper .toSend {
  display: flex;
  /* align-items: center; */
  flex-wrap: wrap;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 5px;
}
.mailDetails__wrapper .toSend .label {
  color: #5c6363;
  display: inline-block;
  flex-shrink: 0;
  margin-right: 5px;
}
.mailDetails__wrapper .toSend .value {
  color: #2775bd;
  /* display: inline-block; */
  max-width: 100%;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: column;
  gap: 5px;  
}
.mailDetails__wrapper .mailTitle {
  font-weight: 700;
  color: #222b2e;
  font-size: 14px;
  line-height: 20px;
  margin: 13px 0px 11px 0px;
}
.mailDetails__wrapper .decriptionText {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #5c6363;
  letter-spacing: 1px;
}
.attachmentWrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 26px;
}
.attachmentCol {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  padding: 12px 20px;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}
.attachmentCol:before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background-color: #2775bd;
  opacity: 0.1;
  transition: 0.4s;
}
.attachmentCol .innerWrapper {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 3;
}
.attachmentCol svg {
  width: 11px;
  height: 22px;
}
.attachmentCol .attchmentName {
  color: #2775bd;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
  display: inline-flex;
  font-size: 14px;
  margin-left: 10px;
}
.attachmentCol .attchmentName .name,
.attachmentCol .attchmentName .extensions {
  display: inline-block;
}
.attachmentCol .attchmentName .name {
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.email_detail_model {
  margin-top: 30px;
  max-width: calc(100% - 30px);
}
.download-predsheet-modal.email_detail_model .inner_box {
  padding: 0px 0px 0px 0px;
  width: 830px;
  max-width: calc(100% - 30px);
}
.mailListing {
  max-height: calc(100vh - 220px) !important;
  overflow-y: auto !important;
}
.mailListing .notificationLoading {
  margin-bottom: 0px;
  padding-top: 0px;
}

.mailListing::-webkit-scrollbar {
  display: none;
}

.attachmentWrapper a {
  text-decoration: none;
}

.email_name {
  font-size: small;
}
.mailList__col .nameTime img {
  margin-left: 12px;
}

.email_detail_model_wrapper::-webkit-scrollbar {
  display: none;
}
