.accountTab {
  .customTable_V1 {
    .customTable {
      table-layout: fixed;
      min-width: 1000px;

      tr {
        td {
          width: 16.66%;
          // text-align: center;
          word-break: break-all;

          &:first-child,
          &:last-child {
            text-align: left;
          }

          .user-select {
            min-width: 0px;
          }
          // &:hover{
          //   white-space:normal;
          //   overflow:visible;
          //   text-overflow:unset;
          // }
        }
        td.selectTD {
          text-align: left;
        }
        td:not(.selectTD) {
          white-space: pre;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

.accountTab {
  .customTable_V1 {
    .customTable.accountListingTable {
      table-layout: auto;
      position: relative;
      .notificationLoading {
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
        margin-bottom: 0px;
      }
      tr {
        td {
          &:first-child {
            width: 50px;
          }
          width: calc(14.28% - 8px);
        }
      }
    }
  }
}
.accountTab .customTable_V1.noCheckbox .customTable.accountListingTable tr td:first-child {
  width: calc(14.28% - 8px);
  text-align: center;
}

.accountTab {
  .dropdownCS {
    display: inline-block;
    position: relative;
    margin-bottom: 5px;
    .toggleBtn {
      font-size: 12px;
      font-weight: 500;
      line-height: 19.2px;
      letter-spacing: 0.04em;
      display: inline-block;
      border: 2px solid #edefef;
      border-radius: 5px;
      padding: 6px 28px 6px 13px;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 9px;
        right: 9px;
        width: 6px;
        height: 6px;
        border-right: 2px solid #59626b;
        border-bottom: 2px solid #59626b;
        transform: rotate(45deg);
      }
      &.active {
        &::before {
          top: 13px;
          transform: rotate(-135deg);
        }
      }
    }
    .dropdownWrapper {
      position: absolute;
      top: 100%;
      right: 0px;
      padding-top: 10px;
      z-index: 9;
      .innerBGWrapper {
        display: inline-block;
        box-shadow: 0px 4px 20px 0px #0000001a;
        background-color: #ffffff;
        border-radius: 4px;
        padding: 8px 0px;
        min-width: 150px;
        .dropdownItem {
          display: flex;
          align-items: center;
          transition: 0.4s;
          width: 100%;
          padding: 10px 17px;
          // margin-bottom: 6px;
          &:last-child {
            margin-bottom: 0px;
          }
          .smallImg {
            width: 14px;
            height: 14px;
          }
          img {
            width: 18px;
            height: 18px;
            object-fit: contain;
            object-position: center;
            flex-shrink: 0;
          }
          .dropdownName {
            display: inline-block;
            font-size: 12px;
            color: #222b2e;
            letter-spacing: 0.6px;
            width: calc(100% - 18px);
            padding-left: 14px;
            text-align: left;
          }
          &:hover {
            background-color: #f1f1f1;
          }
        }
      }
    }
  }
}

.accountPage {
  .innerLeft {
    .accountSelected {
      font-size: 12px;
      font-weight: 500;
      line-height: 19.2px;
      letter-spacing: 0.04em;
      color: #222b2e;
      background-color: #f6f8fa;
      padding: 11px 14px 9px 14px;
      border-radius: 5px;
      margin-right: 0px;
      .count {
        color: #2775bd;
      }
    }
  }
}

.accountPage {
  .left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .innerLeft {
      display: inline-flex;
      align-items: center;

      & > * {
        margin-right: 10px;
      }

      .filterBtn {
        width: 39px;
        height: 39px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px;
        cursor: pointer;
        margin-right: 15px;
        position: relative;
        display: none;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
        }
        .count {
          display: inline-flex;
          position: absolute;
          top: -2px;
          right: -2px;
          border: 2px solid #ffffff;
          border-radius: 50%;
          align-items: center;
          justify-content: center;
          background-color: #ef3e6d;
          color: #ffffff;
          min-width: 12px;
          min-height: 12px;
          font-size: 10px;
          font-weight: 500;
          line-height: 10px;
          letter-spacing: 0.04em;
          padding: 3px 4px;
        }
      }
      .totalAccount {
        font-size: 12px;
        font-weight: 700;
        color: #222b2e;
        line-height: 19.2px;
        letter-spacing: 0.04em;
        // background-color:#F6F8FA;
        min-height: 39px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 6px 16px 4px 16px;
        border-radius: 5px;
        margin-right: 15px;
        .count {
          color: #2775bd;
          display: inline-block;
          margin-left: 6px;
        }
      }
      .primaryOutlineBtn {
        font-size: 12px;
        font-weight: 500;
        line-height: 19.2px;
        letter-spacing: 0.04em;
        color: #2775bd;
        border: 1px solid #2775bd;
        border-radius: 6px;
        transition: 0.4s;
        background-color: transparent;
        padding: 9px 19px 7px 19px;
        min-width: 82px;
        text-align: center;
        display: inline-block;
        cursor: pointer;
        &:hover {
          border: 1px solid #2775bd;
          background-color: #2775bd33;
        }
      }
      .deleteOutlineBtn {
        font-size: 12px;
        font-weight: 500;
        line-height: 19.2px;
        letter-spacing: 0.04em;
        color: #ef3e6d;
        border: 1px solid #ef3e6d;
        border-radius: 6px;
        transition: 0.4s;
        background-color: transparent;
        padding: 9px 19px 7px 19px;
        min-width: 82px;
        text-align: center;
        display: inline-block;
        cursor: pointer;
        margin-left: 15px;
        &:hover {
          border: 1px solid #ef3e6d;
          background-color: #ef3e6d33;
        }
      }
    }

    .innerRight {
      .customer-selectBox {
        position: relative;
        margin-right: 10px;
        padding-right: 8px;
        &::before {
          content: '';
          position: absolute;
          top: calc(50% - 1px);
          transform: translateY(-50%);
          right: 0px;
          height: 20px;
          width: 2px;
          background-color: #e6edf1;
        }
        & > div {
          border: none;
        }
        * {
          font-size: 12px;
          font-weight: 500;
          line-height: 19.2px;
          letter-spacing: 0.04em;
          color: #31384f;
        }
        .paginationSelect__value-container {
          padding-right: 0px;
          padding: 0px !important;
        }
        .paginationSelect__indicator {
          padding: 0px 0px;
          position: relative;
          top: -2px;
        }
        .paginationSelect__control.paginationSelect__control--is-focused {
          box-shadow: unset;
        }
      }
      .customPagination {
        .pagination {
          li {
            display: inline-flex;
            align-items: center;
            &:nth-child(1),
            &:nth-last-child(1) {
              display: none;
            }
            a {
              width: 24px;
              height: 24px;
              border-radius: 5px;
              border: 2px solid transparent;
              line-height: 20px;
              font-size: 12px;
              font-weight: 500;
              letter-spacing: 0.04em;
              color: #2775bd;
              &:hover {
                background-color: transparent !important;
              }
            }
            &.active {
              a {
                background-color: transparent;
                color: #2775bd;
                border: 2px solid #e6edf1;
              }
            }
            &:nth-child(2),
            &:nth-last-child(2) {
              a {
                font-size: 0px !important;
                &::before {
                  top: 6px;
                  left: 5px;
                }
              }
            }
          }
        }
      }
    }
  }
}
// .accountTab .customTable_V1 .customTable tr td.selectTD {
//   background-color: red;
//   width: 150px !important;
// }
// .accountTab .customTable_V1 .customTable tr td.selectField {
//   background-color: yellow;
//   width: 150px !important;
// }
.accountTab .customTable_V1 .customTable tr td .user-select {
  width: 150px;
}
